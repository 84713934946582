.outer-container {
    height: 100%;
    display: flex;
    align-items: center;
    .inner-container {
        display: flex;
        //align-items: center;
        flex-direction: column;
        padding-left: 30px;
        justify-content: space-between;
        height: 100%;
        position: relative;
        &:before {
            position: absolute;
            left: 0;
            content: "";
            width: 30px;
            box-sizing: border-box;
            top: calc(50% - 1px);
            border-top: 2px solid #9155FD;
        }
        .inner {
            position: relative;
            flex: auto;
            text-align: center;
            padding: 5px 0 5px 30px;
            display: flex;
            align-items: center;
            //justify-content: center;
            &:first-of-type {
                &:before {
                    border: 0 none;
                }
                &:after {
                    border-radius: 15px 0 0 0;
                }
            }
            &:last-of-type {
                &:before {
                    border-bottom: 2px solid #9155FD;
                    border-radius: 0 0 0 15px;
                }
                &:after {
                    border: 0 none;
                }
            }
            &:before, &:after {
                content: "";
                position: absolute;
                left: 0;
                width: 30px;
                box-sizing: border-box;
                bottom: calc(50% + 1px);
                height: 50%;
                border-left: 2px solid #9155FD;
            }
            &:after {
                top: calc(50% - 1px);
                border-top: 2px solid #9155FD;
            }
            &.single {
                &:last-of-type {
                    &:before {
                        border-radius: 0;
                        bottom: calc(50% - 0.5px)
                    }
                }
            }
        }
    }
}

