.cell-fixed {
  position: sticky;
  left: 0;
  z-index: 1;
  white-space: nowrap;
  background: #fff;
  border-right: 1px solid rgba(0,0,0,.06);
  &.shadow {
    &:after {
      box-shadow: inset 10px 0 8px -8px rgba(5, 5, 5, 0.06);
      position: absolute;
      top: 0;
      right: 0;
      bottom: -1px;
      width: 30px;
      transform: translate(100%);
      transition: box-shadow .3s;
      content: "";
      pointer-events: none;
    }
  }
}
.cell-item {
  &.border {
    border-right: 1px solid rgba(0,0,0,.06);
  }
}
