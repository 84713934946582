.diff-container pre {
    white-space: pre-wrap;
    word-break: break-all;
}
.diff-container .diff-title {
    color: red;
}

/* 暗黑模式 */
@media (prefers-color-scheme: dark) {
    .diff-container .diff-title {
        color: red;
    }
}
